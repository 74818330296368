import { AdminNotification, UserNotification } from "@prisma/client"
import { useEffect, useState } from "react";
import { notificationService } from "../services/notification.service";
import moment from "moment";
import Modal from "react-responsive-modal";

export const Notification = () => {
  const [loading_admin_notifications, set_loading_admin_notifications] = useState<boolean>(false);
  const [admin_notifications, set_admin_notifications] = useState<AdminNotification[]>([]);
  const [open_new_admin_notification, set_open_new_admin_notification] = useState<boolean>(false);
  const [new_admin_notification, set_new_admin_notification] = useState<Pick<AdminNotification, 'title' | 'description'>>({
    title: '',
    description: ''
  });
  const [open_edit_admin_notification, set_open_edit_admin_notification] = useState<boolean>(false);
  const [edit_admin_notification, set_edit_admin_notification] = useState<Pick<AdminNotification, 'id' | 'title' | 'description'>>({
    id: '',
    title: '',
    description: ''
  });
  const [loading_user_notifications, set_loading_user_notifications] = useState<boolean>(false);
  const [user_notifications, set_user_notifications] = useState<Array<UserNotification & { user: { email: string } }>>([]);
  const [open_new_user_notification, set_open_new_user_notification] = useState<boolean>(false);
  const [new_user_notification, set_new_user_notification] = useState<Pick<UserNotification, 'title' | 'description'> & { emails: string; }>({
    title: '',
    description: '',
    emails: ''
  });
  const [open_edit_user_notification, set_open_edit_user_notification] = useState<boolean>(false);
  const [edit_user_notification, set_edit_user_notification] = useState<Pick<UserNotification, 'id' | 'title' | 'description'>>({
    id: '',
    title: '',
    description: ''
  });
  const registerNewAdminNotification = () => {
    if (new_admin_notification.title && new_admin_notification.description) {
      notificationService.registerAdminNotification(new_admin_notification).then((_new_admin_notification) => {
        set_admin_notifications((_prev) => [..._prev, _new_admin_notification]);
      }).catch((err_msg: string) => {
        console.log(err_msg);
      }).finally(() => {
        set_new_admin_notification({ title: '', description: '' });
        set_open_new_admin_notification(false);
      });
    } else {
      alert("Title and description are required");
    }
  }
  const updateAdminNotification = () => {
    if (edit_admin_notification.title && edit_admin_notification.description) {
      notificationService.updateAdminNotification(edit_admin_notification.id, edit_admin_notification).then((_updated_admin_notification) => {
        set_admin_notifications((_prev) => _prev.map((notification) => notification.id === _updated_admin_notification.id ? _updated_admin_notification : notification));
      }).catch((err_msg: string) => {
        console.log(err_msg);
      }).finally(() => {
        set_open_edit_admin_notification(false);
      });
    } else {
      alert("Title and description are required");
    }
  }
  const deleteAdminNotification = (id: string) => {
    notificationService.removeAdminNotification(id).then((_deleted_admin_notification) => {
      set_admin_notifications((_prev) => _prev.filter((notification) => notification.id !== _deleted_admin_notification.id));
    }).catch((err_msg: string) => {
      console.log(err_msg);
    }).finally(() => {
      set_open_edit_admin_notification(false);
    });
  }
  const getAllUserNotifications = () => {
    set_loading_user_notifications(true);
    notificationService.getAllUserNotifications().then((_user_notifications) => {
      set_user_notifications(_user_notifications);
    }).catch((err_msg: string) => {
      console.error(err_msg);
    }).finally(() => {
      set_loading_user_notifications(false);
    });
  }
  const registerNewUserNotification = () => {
    if (new_user_notification.title && new_user_notification.description && new_user_notification.emails.split(',').map((email) => email.trim()).length > 0) {
      notificationService.registerUserNotification({
        title: new_user_notification.title,
        description: new_user_notification.description,
        emails: new_user_notification.emails.split(',').map((email) => email.trim())
      }).then((_new_user_notification) => {
        getAllUserNotifications();
      }).catch((err_msg: string) => {
        console.log(err_msg);
      }).finally(() => {
        set_new_admin_notification({ title: '', description: '' });
        set_open_new_admin_notification(false);
      });
    } else {
      alert("Title and description are required");
    }
  }
  const updateUserNotification = () => {
    if (edit_user_notification.title && edit_user_notification.description) {
      notificationService.updateUserNotification(edit_user_notification.id, edit_user_notification).then((_updated_user_notification) => {
        getAllUserNotifications();
      }).catch((err_msg: string) => {
        console.log(err_msg);
      }).finally(() => {
        set_open_edit_user_notification(false);
      });
    } else {
      alert("Title and description are required");
    }
  }
  const deleteUserNotification = (id: string) => {
    notificationService.removeUserNotification(id).then((_deleted_user_notification) => {
      set_user_notifications((_prev) => _prev.filter((notification) => notification.id !== _deleted_user_notification.id));
    }).catch((err_msg: string) => {
      console.log(err_msg);
    }).finally(() => {
      set_open_edit_user_notification(false);
    });
  }
  useEffect(() => {
    set_loading_admin_notifications(true);
    notificationService.getAllAdminNotifications().then((_admin_notifications) => {
      set_admin_notifications(_admin_notifications);
    }).catch((err_msg: string) => {
      console.error(err_msg);
    }).finally(() => {
      set_loading_admin_notifications(false);
    });
    getAllUserNotifications();
  }, []);
  return (
    <div className="w-full flex flex-col gap-4">
      <div className="w-full flex justify-end">
        <button className="px-4 py-2 rounded-md bg-green text-white" onClick={() => set_open_new_admin_notification(true)}>Register Admin Notification</button>
      </div>
      <div className="w-full flex flex-col gap-2">
        <div className="grid grid-cols-7 gap-4 text-white border-b border-third-grey">
          <div className="col-span-2">Id</div>
          <div>Title</div>
          <div className="col-span-2">Description</div>
          <div>Created At</div>
          <div>Updated At</div>
        </div>
        {admin_notifications.length > 0 ? admin_notifications.map((notification) => (
          <div
            key={notification.id}
            className="grid grid-cols-7 gap-4 cursor-pointer text-white border-b border-third-grey"
            onClick={() => {
              set_edit_admin_notification(notification);
              set_open_edit_admin_notification(true);
            }}>
            <div className="col-span-2">{notification.id}</div>
            <div>{notification.title}</div>
            <div className="col-span-2">{notification.description}</div>
            <div>{moment(notification.created_at).format('YYYY-MM-DD HH:mm:ss')}</div>
            <div>{moment(notification.updated_at).format('YYYY-MM-DD HH:mm:ss')}</div>
          </div>
        )) : (
          <p className="w-full text-white text-center">No registered admin notifications</p>
        )}
      </div>
      <Modal open={open_new_admin_notification} onClose={() => set_open_new_admin_notification(false)} center>
        <h2>New Admin Notification</h2>
        <hr />
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Title" title="Required title of new notification">Title(*)</label>
          <input className="grow shrink border p-2" type="text" id="Title" value={new_admin_notification.title} onChange={(e) => set_new_admin_notification((_prev) => ({ ..._prev, title: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Description" title="Required description of new notification">Description(*)</label>
          <input className="grow shrink border p-2" type="text" id="Description" value={new_admin_notification.description} onChange={(e) => set_new_admin_notification((_prev) => ({ ..._prev, description: e.target.value }))} />
        </div>
        <div className="flex justify-between items-center mt-2">
          <button className="px-3 py-1 border text-black" onClick={() => set_open_new_admin_notification(false)}>Close</button>
          <button className="px-3 py-1 bg-green text-white" disabled={loading_admin_notifications} onClick={registerNewAdminNotification}>{loading_admin_notifications ? 'Registering' : 'Register'}</button>
        </div>
      </Modal>
      <Modal open={open_edit_admin_notification} onClose={() => set_open_edit_admin_notification(false)} center>
        <h2>Edit Admin Notification</h2>
        <hr />
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Title" title="Required title of new notification">Title(*)</label>
          <input className="grow shrink border p-2" type="text" id="Title" value={edit_admin_notification.title} onChange={(e) => set_edit_admin_notification((_prev) => ({ ..._prev, title: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Description" title="Required description of new notification">Description(*)</label>
          <input className="grow shrink border p-2" type="text" id="Description" value={edit_admin_notification.description} onChange={(e) => set_edit_admin_notification((_prev) => ({ ..._prev, description: e.target.value }))} />
        </div>
        <div className="flex justify-between items-center mt-2">
          <button className="px-3 py-1 border text-black" onClick={() => set_open_edit_admin_notification(false)}>Close</button>
          <div className="flex items-center gap-2">
            <button className="px-3 py-1 bg-red-500 text-white" disabled={loading_admin_notifications} onClick={() => deleteAdminNotification(edit_admin_notification.id)}>{loading_admin_notifications ? 'Deleting' : 'Delete'}</button>
            <button className="px-3 py-1 bg-green text-white" disabled={loading_admin_notifications} onClick={updateAdminNotification}>{loading_admin_notifications ? 'Updating' : 'Update'}</button>
          </div>
        </div>
      </Modal>
      <div className="w-full flex justify-end">
        <button className="px-4 py-2 rounded-md bg-green text-white" onClick={() => set_open_new_user_notification(true)}>Register User Notification</button>
      </div>
      <div className="w-full flex flex-col gap-2">
        <div className="grid grid-cols-8 gap-4 text-white border-b border-third-grey">
          <div className="col-span-2">Id</div>
          <div>User</div>
          <div>Title</div>
          <div className="col-span-2">Description</div>
          <div>Created At</div>
          <div>Updated At</div>
        </div>
        {user_notifications.length > 0 ? user_notifications.map((notification) => (
          <div
            key={notification.id}
            className="grid grid-cols-8 gap-4 cursor-pointer text-white border-b border-third-grey"
            onClick={() => {
              set_edit_user_notification(notification);
              set_open_edit_user_notification(true);
            }}
          >
            <div className="col-span-2">{notification.id}</div>
            <div className="break-all">{notification.user.email}</div>
            <div>{notification.title}</div>
            <div className="col-span-2">{notification.description}</div>
            <div>{moment(notification.created_at).format('YYYY-MM-DD HH:mm:ss')}</div>
            <div>{moment(notification.updated_at).format('YYYY-MM-DD HH:mm:ss')}</div>
          </div>
        )) : (
          <p className="w-full text-white text-center">No registered user notifications</p>
        )}
      </div>
      <Modal open={open_new_user_notification} onClose={() => set_open_new_user_notification(false)} center>
        <h2>New User Notification</h2>
        <hr />
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Title" title="Required title of new user notification">Title(*)</label>
          <input className="grow shrink border p-2" type="text" id="Title" value={new_user_notification.title} onChange={(e) => set_new_user_notification((_prev) => ({ ..._prev, title: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Description" title="Required description of new notification">Description(*)</label>
          <input className="grow shrink border p-2" type="text" id="Description" value={new_user_notification.description} onChange={(e) => set_new_user_notification((_prev) => ({ ..._prev, description: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Emails" title="Required description of new notification">Emails(*)</label>
          <input className="grow shrink border p-2" type="text" id="Emails" value={new_user_notification.emails} onChange={(e) => set_new_user_notification((_prev) => ({ ..._prev, emails: e.target.value }))} />
        </div>
        <div className="flex justify-between items-center mt-2">
          <button className="px-3 py-1 border text-black" onClick={() => set_open_new_user_notification(false)}>Close</button>
          <button className="px-3 py-1 bg-green text-white" disabled={loading_user_notifications} onClick={registerNewUserNotification}>{loading_user_notifications ? 'Registering' : 'Register'}</button>
        </div>
      </Modal>
      <Modal open={open_edit_user_notification} onClose={() => set_open_edit_user_notification(false)} center>
        <h2>Edit User Notification</h2>
        <hr />
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Title" title="Required title of new notification">Title(*)</label>
          <input className="grow shrink border p-2" type="text" id="Title" value={edit_user_notification.title} onChange={(e) => set_edit_user_notification((_prev) => ({ ..._prev, title: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Description" title="Required description of new notification">Description(*)</label>
          <input className="grow shrink border p-2" type="text" id="Description" value={edit_user_notification.description} onChange={(e) => set_edit_user_notification((_prev) => ({ ..._prev, description: e.target.value }))} />
        </div>
        <div className="flex justify-between items-center mt-2">
          <button className="px-3 py-1 border text-black" onClick={() => set_open_edit_user_notification(false)}>Close</button>
          <div className="flex items-center gap-2">
            <button className="px-3 py-1 bg-red-500 text-white" disabled={loading_user_notifications} onClick={() => deleteUserNotification(edit_user_notification.id)}>{loading_user_notifications ? 'Deleting' : 'Delete'}</button>
            <button className="px-3 py-1 bg-green text-white" disabled={loading_user_notifications} onClick={updateUserNotification}>{loading_user_notifications ? 'Updating' : 'Update'}</button>
          </div>
        </div>
      </Modal>
    </div>
  )
}