import axios, { AxiosError } from "axios";
import { API_BACKEND_ENDPOINT } from "../config";
import { AdminNotification, UserNotification } from "@prisma/client";

class NotificationService {
  async getAllAdminNotifications() {
    return new Promise<AdminNotification[]>((resolve, reject) => {
      axios.get(`${API_BACKEND_ENDPOINT}/notification`).then((res: { data: AdminNotification[] }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
  registerAdminNotification(notification: Pick<AdminNotification, 'title' | 'description'>) {
    return new Promise<AdminNotification>((resolve, reject) => {
      axios.post(`${API_BACKEND_ENDPOINT}/notification`, notification).then((res: { data: AdminNotification }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
  updateAdminNotification(id: string, notification: Partial<Pick<AdminNotification, 'title' | 'description'>>) {
    return new Promise<AdminNotification>((resolve, reject) => {
      axios.patch(`${API_BACKEND_ENDPOINT}/notification/${id}`, notification).then((res: { data: AdminNotification }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
  removeAdminNotification(id: string) {
    return new Promise<AdminNotification>((resolve, reject) => {
      axios.delete(`${API_BACKEND_ENDPOINT}/notification/${id}`).then((res: { data: AdminNotification }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
  getAllUserNotifications() {
    return new Promise<Array<UserNotification & { user: { email: string } }>>((resolve, reject) => {
      axios.get(`${API_BACKEND_ENDPOINT}/user-notification`).then((res: { data: Array<UserNotification & { user: { email: string } }> }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
  registerUserNotification(new_user_notification: Pick<UserNotification, 'title' | 'description'> & { emails: string[]; }) {
    return new Promise<UserNotification>((resolve, reject) => {
      axios.post(`${API_BACKEND_ENDPOINT}/user-notification`, new_user_notification).then((res: { data: UserNotification }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
  updateUserNotification(id: string, user_notification: Partial<Pick<UserNotification, 'title' | 'description'>>) {
    return new Promise<UserNotification>((resolve, reject) => {
      axios.patch(`${API_BACKEND_ENDPOINT}/user-notification/${id}`, user_notification).then((res: { data: UserNotification }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
  removeUserNotification(id: string) {
    return new Promise<AdminNotification>((resolve, reject) => {
      axios.delete(`${API_BACKEND_ENDPOINT}/user-notification/${id}`).then((res: { data: AdminNotification }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
}

export const notificationService = new NotificationService();
